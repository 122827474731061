<template>
	<v-container>
		<h1>Заняття "{{ lesson.name }}"</h1>

		<v-card
			outlined
			:loading="loading">
			<validation-observer
				ref="observer"
				v-slot="{ invalid, handleSubmit }">
				<v-form ref="editForm"
					@submit.prevent="handleSubmit(saveLesson)"
					@reset.native="cancelEditForm">

					<v-card-title>Заняття</v-card-title>
					<v-divider></v-divider>

					<v-card-text>
						<v-row>
							<v-col
								cols="12"
								md="9"
								class="py-0">
								<vv-text-field
									v-model="lesson.name"
									label="Назва заняття"
									rules="required"
									name="name"
									required
									outlined
									dense>
								</vv-text-field>
							</v-col>
							<v-col
								cols="12"
								md="3"
								class="py-0">
								<v-switch
									v-model="lesson.is_active"
									label="Активне"
									class="mt-1"
									inset>
								</v-switch>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="py-0">
								<vv-text-field
									v-model="lesson.video_id"
									label="ID відео на vimeo"
									hint="Щось типу 827235335"
									name="video_id"
									outlined
									dense>
								</vv-text-field>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="py-0">
								<vv-autocomplete
									v-model="lesson.course_id"
									:items="courses"
									item-text="name"
									item-value="id"
									label="Курс"
									name="course"
									outlined
									dense>
								</vv-autocomplete>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="py-0">
								<vv-autocomplete
									v-model="lesson.tests"
									:items="tests"
									item-text="name"
									item-value="id"
									label="Тести"
									name="tests"
									deletable-chips
									return-object
									hide-selected
									small-chips
									multiple
									outlined
									chips
									dense>
								</vv-autocomplete>
							</v-col>
							<v-col
								cols="12"
								class="py-0">
								<vv-textarea
									v-model="lesson.text"
									label="Текст"
									hint="Введи текст у вигляді html"
									name="description"
									outlined
									dense>
								</vv-textarea>
							</v-col>
							<v-col
								cols="12"
								class="py-0">
								<vv-autocomplete
									v-model="lesson.users"
									:items="users"
									label="Учні"
									item-text="full_name"
									item-value="id"
									name="users"
									deletable-chips
									return-object
									hide-selected
									small-chips
									multiple
									outlined
									chips
									dense>
								</vv-autocomplete>
							</v-col>
						</v-row>
					</v-card-text>
					
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							depressed
							type="reset">
							Відмінити
						</v-btn>
						<v-btn
							color="primary"
							depressed
							type="submit"
							:disabled="invalid">
							Зберегти
						</v-btn>
					</v-card-actions>
				</v-form>
			</validation-observer>
		</v-card>

		<!-- <v-card
			outlined
			:loading="loading"
			class="mt-8">
			<v-card-title>Учні з повним дотупом до заняття</v-card-title>
			<v-divider></v-divider>

			<v-card-text>
				<v-list>
					<v-list-item
						v-for="user in lesson.users"
						:key="user.id">

						<v-list-item-content>
							<v-list-item-title>
								{{ user.name }} {{ user.surname }}
							</v-list-item-title>
						</v-list-item-content>

						<v-list-item-action>
							<v-switch
								v-model="user.is_full_access"
								inset
								@change="(e) => updateLessonUser(e, user.id)">
							</v-switch>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-card-text>
		</v-card> -->
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { apiFactory } from '../api/apiFactory'

import User from '../models/User'
import Course from '../models/Course'
import Lesson from '../models/Lesson'
import Test from '../models/Test'

const usersLessonsApi = apiFactory.get('usersLessons')

export default {
	props: {
		id: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		headers: [
			{
				text: '',
				value: 'is_active',
				align: 'center',
				width: '4em',
			},
			{
				text: 'Назва заняття',
				value: 'name',
			},
			{
				text: 'Курс',
				value: 'course.name',
			},
			{
				text: 'ID Відео',
				value: 'video_id',
			},
			{
				text: 'Файли',
				value: 'files',
			},
			{
				text: 'Учні',
				value: 'users_count',
			},
			{
				text: 'Востаннє змінено',
				value: 'updated_at',
			},
			{
				text: 'Дії',
				value: 'action',
				sortable: false,
				align: 'center',
				width: '10em',
			},
		],
		search: '',
		loading: false,
	}),
	computed: {
		users() {
			return User.all()
		},
		courses() {
			return Course.all()
		},
		lesson() {
			return Lesson.find(this.id) || {}
		},
		lessons() {
			return Lesson.all()
		},
		lessonCourse() {
			return this.lesson?.course || {}
		},
		tests() {
			return Test.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadUsers() {
			try {
				await User.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список учнів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadCourses() {
			try {
				await Course.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список курсів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadLesson() {
			try {
				this.loading = true
				await Lesson.fetchGet(this.id)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},
		
		async saveLesson() {
			try {
				this.loading = true
				await Lesson.fetchUpdate(this.lesson)
				this.notify({
					text: 'Заняття успішно збережено',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося зберегти заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async deleteLesson(lesson) {
			try {
				const confirm = await this.$refs.confirm.open(
					'Видалити заняття',
					'Ви дійсно хочете видалити заняття? Цю дію неможливо відмінити.',
					{ color: 'red' })
				if (confirm) {
					this.loading = true
					await Lesson.fetchDelete(lesson.districtId)
					this.notify({
						text: 'Заняття видалено',
						color: 'success',
					}, { root: true })
				}
			} catch (error) {
				this.notify({
					text: 'Не вдалося видалити заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async updateLessonUser(value, userid) {
			try {
				this.loading = true
				await usersLessonsApi.update(userid, this.id, {
					'is_full_access': value,
				})
				this.notify({
					text: 'Доступ учня до заняття змінено',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async loadTests(subjectId) {
			try {
				await Test.fetchGetAllBySubject(subjectId)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список тестів для цього предмету',
					color: 'error',
				}, { root: true })
			}
		},

		cancelEditForm() {
			// this.lesson = new Lesson()
		},
	},
	watch: {
		'lessonCourse.subject_id': 'loadTests',
	},
	mounted() {
		this.loadUsers()
		this.loadCourses()
		this.loadLesson()
	},
}
</script>